<template>
    <div class="row">
        <LeftSection :list_check_box="list_check_box" :links="data.data_links" />
         <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">         
            <template v-for="(item, key) in list_check_box" >               
                <div class="box-white-fiche" v-if="show_block.includes(key) && item.enable" :id="key" :key="key">
                      <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3>
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->
                    
                    <!-- Section Detail Start -->
                    <!--information_general start-->
                    <InformationGeneral v-if="key == 'detail_information_general' && item.show" />
                    <!--information_general end-->

                    <!--Indication start-->
                    <Indication  v-else-if="key == 'detail_indication' && item.show"/>
                    <!--Indication end-->

                    <!-- treatment_line start -->
                    <TreatmentLine v-else-if="key == 'treatment_line' && item.show"/>
                    <!-- treatment_line end -->

                    <!--MA spec start-->
                    <div class="content-onglets table" v-else-if="key == 'detail_MaSpac' && item.show">
                        <table class="comparateur2">
                            <tr colspan="3">
                                <td class="bold"><strong> {{$t('TRS_AMM')}} </strong></td>
                                <td> {{data['iqwig']['iqwig_type_amm'] ? $t('ceesp_type_amm_'+data['iqwig']['iqwig_type_amm']) : '-'}} </td>
                            </tr>
                        </table>
                    </div>
                    <!--MA spec end-->

                    <!--Conclusion start-->
                    <Conclusion v-else-if="key == 'detail_Conclusion' && item.show" />  
                    <!--Conclusion end-->

                    <!-- Comment start -->
                    <div class="content-onglets table" v-else-if="key == 'detail_Commentfr' && item.show">
                        <table class="comparateur2">
                            <thead>
                                <tr>
                                    <th width="100%" class="text-orange"> {{$t('iqwig_comment')}} </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style="text-align: left;" >
                                       <spam v-html="check_empty($i18n.locale == 'de' ? data.iqwig['commentde'] : data.iqwig['commentfr'])"></spam>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- Comment end -->

                    <!--Executive summury of the benefit assessment start-->
                    <div class="content-onglets table" v-else-if="key == 'detail_background' && item.show">    
                        <div class="box-toggle" v-if="$i18n.locale == 'de' && data.iqwig['backgroundde'] || $i18n.locale != 'de' && data.iqwig['background']">
                            <a class="title title-tb" @click="background_show = !background_show">
                                {{$t('iqwig_background')}} 
                                <em :class="background_show ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                            </a>                             
                            <section v-if="background_show">
                                <span v-html="check_empty($i18n.locale == 'de' ? data.iqwig['backgroundde'] : data.iqwig['background'])"></span>
                            </section>
                        </div>
                        <div class="box-toggle" v-if="data.iqwig.iqwig_research_question.length > 0">
                            <a class="title title-tb" @click="research_question_show = !research_question_show">
                                {{$t('iqwig_research')}}
                                <em :class="research_question_show ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                            </a>                             
                            <section v-if="research_question_show">
                                <div v-for="(item_iqwig_research_question, key_iqwig_research_question) in data.iqwig.iqwig_research_question" :key="'key_iqwig_research_question_'+key_iqwig_research_question">
                                    <strong v-if="$i18n.locale == 'de' && item_iqwig_research_question['titlede']" >{{item_iqwig_research_question['titlede']}}</strong>
                                    <strong v-else-if="$i18n.locale != 'de' && item_iqwig_research_question['title']" >{{item_iqwig_research_question['title']}}</strong>
                                    
                                    <span v-if="$i18n.locale == 'de' && item_iqwig_research_question['textde']" v-html="item_iqwig_research_question['textde']"></span>
                                    <span v-else-if="$i18n.locale != 'de' && item_iqwig_research_question['text']" v-html="item_iqwig_research_question['text']"></span>
                                </div>
                            </section>
                        </div>      
                        <div class="box-toggle" v-if="data.iqwig['check_benefit_assessment'] && data.iqwig['benefit_assessment'] || data.iqwig['benefit_assessmentde'] || data.iqwig.iqwig_result_benefit_assessment.length > 0" >
                                <a class="title title-tb" @click="benefit_assessment_show = !benefit_assessment_show">
                                    {{$t('iqwig_benefit_assessment')}} 
                                    <em :class="benefit_assessment_show ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>                             
                                <section v-if="benefit_assessment_show">
                                    <span v-html="$i18n.locale == 'de' ? data.iqwig['benefit_assessmentde'] : data.iqwig['benefit_assessment']"></span>
                    
                                    <template v-if="data.iqwig.iqwig_result_benefit_assessment">
                                        <div v-for="(item_result_benefit_assessment, key_result_benefit_assessment) in data.iqwig.iqwig_result_benefit_assessment" :key="'key_result_benefit_assessment_'+key_result_benefit_assessment">
                                            <strong v-if="$i18n.locale == 'de' && item_result_benefit_assessment['titlede']" v-html="item_result_benefit_assessment['titlede']"></strong>
                                            <strong v-else-if="$i18n.locale != 'de' && item_result_benefit_assessment['title']" v-html="item_result_benefit_assessment['title']"></strong>
                                            
                                            <span v-if="$i18n.locale == 'de' && item_result_benefit_assessment['textde']" v-html="item_result_benefit_assessment['textde']" ></span>
                                            <span v-else-if="$i18n.locale != 'de' && item_result_benefit_assessment['text']" v-html="item_result_benefit_assessment['text']" ></span>
                                            
                                            <template v-if="item_result_benefit_assessment['iqwig_result_benefit_assessment_endpoint'].length > 0">
                                                <a :key="'iqwig_result_benefit_assessment_endpoint_'+key_result_benefit_assessment" 
                                                    :href="FULL_BASE_URL+'/pdf/result_benefit_assessment_endpoint/'+data.iqwig.id+'/iqwig/'+item_result_benefit_assessment['id']" target="_blank" rel="noopener" style="color: #000; float: right;">
                                                    <em class="fa fa-download"></em>
                                                </a>

                                                <table class="comparateur2 vertical-md" :key="'table_iqwig_result_benefit_assessment_endpoint_'+key_result_benefit_assessment">
                                                    <tbody>
                                                        <tr>
                                                            <th colspan="2" width="60%" style="text-align: left;" >
                                                                <p style=" color: #ff7e00" v-if="$i18n.locale == 'de' && item_result_benefit_assessment['titlede']">{{item_result_benefit_assessment['titlede']}}</p>
                                                                <p style=" color: #ff7e00" v-if="$i18n.locale != 'de' && item_result_benefit_assessment['title']">{{item_result_benefit_assessment['title']}}</p>
                                                                
                                                                <template v-if="item_result_benefit_assessment['nct_numbers_model']">
                                                                    <template v-for="(item_nct_number, key_nct_number) in item_result_benefit_assessment['nct_numbers_model']" >
                                                                        <div v-if="item_nct_number['text']" :key="'key_nct_number_'+key_nct_number"> {{item_nct_number['text']}} </div>
                                                                    </template>
                                                                </template>

                                                            </th>
                                                            <th width="20%"> {{$t('Probability of added benefit')}} </th>
                                                            <th width="20%"> {{$t('Extend of the added benefit')}} </th>
                                                        </tr>

                                                       
                                                        <template v-for="(items, key) in gen_IqwigResultBenefitAssessmentEndpoint(item_result_benefit_assessment['iqwig_result_benefit_assessment_endpoint'])" >
                                                            <template v-for="(value, key_1) in items" :key="'key'+key+'key_1'+key_1">
                                                                <tr>
                                                                    <th width="20%" :rowspan="items.length" v-if="key_1 == 0">
                                                                        {{value['type'] ? $t('gba.'+value['type']) : '-'}}
                                                                    </th>
                                                                    <td width="40%" style="color: #ff7e00;">
                                                                        {{value['endpoint'] ? value['endpoint'] : '-'}} 
                                                                    </td>
                                                                    <td>
                                                                        {{value['probability'] ? $t('iqwig.'+value['probability']) : '-' }}
                                                                    </td>
                                                                    <td>
                                                                        {{value['added_benefit'] ? $t('gba.'+value['added_benefit'].replaceAll('"', "&")).replaceAll('gba.', '').replaceAll('&', '"') : '-' }}
                                                                    </td>
                                                                </tr>
                                                            </template>
                                                        </template>
                                                    </tbody>
                                                </table>
                                            </template>

                                            <template v-else>
                                                <template v-if="item_result_benefit_assessment['nctnumber']">
                                                    <template v-for="(item_nct_number, key_nct_number) in item_result_benefit_assessment['nctnumber']">
                                                        <div :key="'key_nct_number_'+key_nct_number" v-if="item_nct_number['text']" > {{item_nct_number['text']}} </div>
                                                    </template>
                                                </template>
                                            </template>

                                            
                                            <template v-if="item_result_benefit_assessment['mortality']">
                                                <div :key="'Mortality_'+key_result_benefit_assessment"><strong> {{$t('Mortality')}} </strong></div>
                                                 <span v-if="$i18n.locale == 'de'" v-html="item_result_benefit_assessment['mortalityde']" :key="'Mortality_text'+key_result_benefit_assessment"></span>
                                                 <span v-else v-html="item_result_benefit_assessment['mortality']" :key="'Mortality_text'+key_result_benefit_assessment"></span>
                                            </template> 

                                            <template v-if="item_result_benefit_assessment['morbidity']">
                                                <div :key="'Morbidity_'+key_result_benefit_assessment"><strong> {{$t('Morbidity')}} </strong></div>
                                                 <span v-if="$i18n.locale == 'de'" v-html="item_result_benefit_assessment['morbidityde']" :key="'Morbidity_text'+key_result_benefit_assessment"></span> 
                                                 <span v-else v-html="item_result_benefit_assessment['morbidity']" :key="'Morbidity_text'+key_result_benefit_assessment"></span> 
                                            </template> 

                                            <template v-if="item_result_benefit_assessment['quality_of_life']">
                                                <div :key="'Quality_of_life_'+key_result_benefit_assessment"><strong> {{$t('Quality_of_life')}} </strong></div>
                                                 <span v-if="$i18n.locale == 'de'" v-html="item_result_benefit_assessment['quality_of_lifede']" :key="'Quality_of_life_text'+key_result_benefit_assessment"></span>
                                                 <span v-else v-html="item_result_benefit_assessment['quality_of_life']" :key="'Quality_of_life_text'+key_result_benefit_assessment"></span>
                                            </template> 

                                            <template v-if="item_result_benefit_assessment['adverse_ivents']">
                                                <div :key="'Adverse_events_'+key_result_benefit_assessment"><strong> {{$t('Advers_events')}} </strong></div>
                                                 <span v-if="$i18n.locale == 'de'" v-html="item_result_benefit_assessment['adverse_iventsde']" :key="'Adverse_events_text'+key_result_benefit_assessment"></span>
                                                 <span v-else v-html="item_result_benefit_assessment['adverse_ivents']" :key="'Adverse_events_text'+key_result_benefit_assessment"></span>
                                            </template> 
  
                                            <template v-if="item_result_benefit_assessment['relevent_subgroups']">
                                                <div :key="'Relevant_subgroups_'+key_result_benefit_assessment"><strong> {{$t('Relevant subgroups')}} </strong></div>
                                                 <span v-if="$i18n.locale == 'de'" v-html="item_result_benefit_assessment['relevent_subgroupsde']" :key="'Relevant_subgroups_text'+key_result_benefit_assessment"></span>
                                                 <span v-else v-html="item_result_benefit_assessment['relevent_subgroups']" :key="'Relevant_subgroups_text'+key_result_benefit_assessment"></span>
                                            </template> 

                                            <template v-if="item_result_benefit_assessment['risk_of_bias']">
                                                <div :key="'Risk_of_bias_'+key_result_benefit_assessment"><strong> {{$t('Risk of bias')}} </strong></div>
                                                 <span v-if="$i18n.locale == 'de'" v-html="item_result_benefit_assessment['risk_of_biasde']" :key="'Risk_of_bias_text'+key_result_benefit_assessment"></span>
                                                 <span v-else v-html="item_result_benefit_assessment['risk_of_bias']" :key="'Risk_of_bias_text'+key_result_benefit_assessment"></span>
                                            </template> 

                                            <template v-if="item_result_benefit_assessment['other']">
                                                <div :key="'Other_'+key_result_benefit_assessment"><strong> {{$t('Other')}} </strong></div>
                                                 <span v-if="$i18n.locale == 'de'" v-html="item_result_benefit_assessment['otherde']" :key="'Other_text'+key_result_benefit_assessment"></span>
                                                 <span v-else v-html="item_result_benefit_assessment['other']" :key="'Other_text'+key_result_benefit_assessment"></span>
                                            </template> 

                                        </div>
                                    </template>                             
                                </section>
                        </div>
                    </div>  
                    <!--Executive summury of the benefit assessment end-->

                    <!--Extent and probability of the added benefit start-->
                    <div class="content-onglets table" v-else-if="key == 'detail_IqwigExtentProbability' && item.show">
                        <div class="accordion accordion-table">
                            <table class="comparateur2 table-heading top" style="margin-bottom:0px">
                                <tbody>
                                    <tr>
                                        <th class="no-border" >&nbsp;</th>
                                        <td class="bg-white"  width="16.666%" style="border-top:1px solid #e6e9ee;"> {{$t('iqwig_pro_add_benefit')}} </td>
                                        <td class="bg-white"  width="16.666%" style="border-top:1px solid #e6e9ee;"> {{$t('iqwig_overall')}} </td>
                                    </tr>
                                </tbody>
                            </table>
                                <div class="accordion-group" v-for="(item_iqwig_extent_probability, key_iqwig_extent_probability) in data.iqwig.iqwig_extent_probability" :key="'key_iqwig_extent_probability_'+key_iqwig_extent_probability">
                                    <table class="comparateur2 table-heading border-top-gray" style="position: relative; margin-bottom:0px">
                                        <tbody>
                                            <tr>
                                                <th class="text-left bg-white" >
                                                    {{$i18n.locale == 'de' ? item_iqwig_extent_probability['titlede'] : item_iqwig_extent_probability['title']}}
                                                </th>
                                                <td class="bg-white"  width="16.666%">
                                                    {{item_iqwig_extent_probability['iqwig_probability']['name'+$i18n.locale]}}
                                                </td>
                                                <td class="bg-white"  width="16.666%">
                                                    {{$t("added_benefit_"+item_iqwig_extent_probability['added_benefit'])}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <a class="accordion-toggle2 text-blank" @click="iqwig_extent_probability_toggle(key_iqwig_extent_probability)">
                                        <em :class="iqwig_extent_probability_show[key_iqwig_extent_probability] == 1 ? 'icons-2014 icon-minus-s' : 'icons-2014 icon-plus-s'"></em>
                                    </a>
                                    <div v-if="iqwig_extent_probability_show[key_iqwig_extent_probability] == 1" class="accordion-body">                                                
                                        <div v-if="$i18n.locale == 'de'" v-html="item_iqwig_extent_probability['textde']"></div>
                                        <div v-else v-html="item_iqwig_extent_probability['text']"></div>
                                    </div>
                                </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    <!--Extent and probability of the added benefit end-->

                    <!--Information retrieval and study pool start-->  
                    <div class="content-onglets table" v-else-if="key == 'detail_studie_assessment' && item.show">

                        <!-- if (!empty($this->data['EvaluationEssaisCliniqueAspiration'])) {
                            require_once 'sub_detail/sub_clinical_trial.ctp';
                        } -->
                        
                        <div class="textJustify">
                            <div class="box-toggle" v-if="$i18n.locale == 'de' && data.iqwig['studie_assessmentde'] || $i18n.locale != 'de' && data.iqwig['studie_assessment']">
                                <a class="title title-tb"  @click="studie_assessment_show = !studie_assessment_show">
                                    {{$t('iqwig_study_assessment')}}
                                    <em :class="studie_assessment_show ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>                             
                                <section v-if="studie_assessment_show">
                                    <span v-html="$i18n.locale == 'de' ? data.iqwig['studie_assessmentde'] : data.iqwig['studie_assessment']"></span>
                                </section>
                            </div>           
                            <div class="box-toggle" v-if="$i18n.locale == 'de' && data.iqwig['study_characterde'] || $i18n.locale != 'de' && data.iqwig['study_character'] || data.iqwig.iqwig_study_character.length > 0">
                                <a class="title title-tb" @click="study_character_show = !study_character_show">
                                    {{$t('iqwig_characteristics')}} 
                                    <em :class="study_character_show ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>                             
                                <section v-if="study_character_show">
                                    <span v-html="$i18n.locale == 'de' ? data.iqwig['study_characterde'] : data.iqwig['study_character']"></span>
                                    
                                    <template v-for="(value, key) in data.iqwig.iqwig_study_character" :key="'iqwig_study_b_'+key">
                                        <strong> {{$i18n.locale == 'de' ? value['titlede'] : value['title']}} </strong>
                                        <span v-html="$i18n.locale == 'de' ? value['textde'] : value['text']" ></span>
                                    </template>

                                </section>
                            </div>
                        </div>
                    </div>
                    <!--Information retrieval and study pool end-->

                    <!--evaluation_o_add start-->
                    <div class="content-onglets textJustify" v-else-if="key == 'detail_evaluation_o_add' && item.show">
                        <p v-if="$i18n.locale == 'de'" v-html="data.iqwig['evaluation_o_addde']"></p>
                        <p v-else v-html="data.iqwig['evaluation_o_add']"></p>
                    </div>
                    <!--evaluation_o_add end-->

                    <!--Press release start--> 
                    <div class="content-onglets" v-else-if="key == 'detail_IqwigPressRelease' && item.show">
                        <div class="box-toggle" v-for="(item_press_release, key_press_release) in data.iqwig.iqwig_press_release" :key="'key_press_release_'+key_press_release">
                            <a class="title title-tb"  @click="PressRelease_toggle(key_press_release)">
                                {{$i18n.locale == 'de' ? item_press_release['titlede'] : item_press_release['title']}}
                                <em :class="PressRelease_show[key_press_release] == 1 ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                            </a>                             
                            <section v-if="PressRelease_show[key_press_release] == 1">
                                <span v-if="$i18n.locale == 'de'" v-html="item_press_release['textde']"></span>
                                <span v-else v-html="item_press_release['text']"></span>
                            </section>
                        </div>
                    </div>
                    <!--Press release end-->  

                    <!-- 'sub_detail/EconomicEvaluation.ctp' -->
                    <EconomicEvaluation v-else-if="key == 'detail_EconomicEvaluation' && item.show" :data="data.data_links['EconomicEvaluation']" />
                
                    <!-- evaluation_essais_clinique start--> 
                    <EssaisClinique v-else-if="key == 'detail_clinical_trials' && item.show" :datas="data.essais_clinique"/>
                    <!-- evaluation_essais_clinique end-->

                    <KeyDocument v-else-if="key=='detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist"/>

                    <!-- Section Detail End -->

                </div>
            </template>
            
            <!-- Link Section Start-->       
            <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->
            
        </div>
        <!-- Right Section End -->
    </div>
</template>

<script>
import LeftSection from '../LeftSection.vue';
import TreatmentLine from '../treatment_line.vue'
import EconomicEvaluation from '../economic_evaluation.vue'
import EssaisClinique from '../../elements/essais_clinique_detail.vue'
import Conclusion from '../conclusion.vue'
import LinkAgency from '../LinkAgency.vue'
import KeyDocument from '../key_document.vue'
import InformationGeneral from '../InformationGeneral.vue'
import Indication from '../Indication.vue'
import { DDMMMYY, check_empty, check_detail_conclusion, key_documents } from '../../../utils'

export default{
    name: 'iqwig',
    components : {
        LeftSection,
        TreatmentLine,
        EconomicEvaluation,
        Conclusion,
        LinkAgency,
        EssaisClinique,
        KeyDocument,
        InformationGeneral,
        Indication
    },
    data(){
        return {
            list_check_box : {
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange
                detail_information_general : { 'title' : 'information generale', 'show' : true, 'enable' : false, 'orange_text':false},               
                detail_indication : {'title' : 'indication', 'show' : true, 'enable' : false, 'orange_text':false },
                treatment_line : {'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_MaSpac : {'title' : 'information generale', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_Conclusion : {'title' : 'Rationale and Commentary', 'show' : true, 'enable' : false, 'orange_text':true },             
                detail_Commentfr : {'title' : 'iqwig_comment', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_background : {'title' : 'Executive summury of the benefit assessment', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_IqwigExtentProbability : {'title' : 'Extent and probability of the added benefit', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_studie_assessment : {'title' : 'Information retrieval and study pool', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_evaluation_o_add : {'title' : 'Evaluation of added benefit at outcome level', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_IqwigPressRelease : {'title' : 'press_release', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_EconomicEvaluation : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true },             
                detail_clinical_trials : {'title' : 'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true },             
                detail_key_documents : {'title' : 'Key documents', 'show' : true, 'enable' : false, 'orange_text':false },
            },
            background_show : false,
            research_question_show : false,
            benefit_assessment_show : false,
            studie_assessment_show : false,
            study_character_show : false,
            PressRelease_show : {},
            iqwig_extent_probability_show : {},
            
        }
    },
    created(){
        // condition to show check box in left section [enable]
        if(this.data && this.data.iqwig){
            
            if(this.data['ma_date'])
            {
                this.list_check_box['detail_information_general'].enable = true
            }
            if((this.data['indication_de'] && this.$i18n.locale === 'de') || (this.data['indication_en'] && this.$i18n.locale !== 'de'))
            { 
                this.list_check_box['detail_indication'].enable = true
            }
            if(this.data.treatment_line || this.data.specificity || this.data.administration)  
            { 
                this.list_check_box['treatment_line'].enable = true
            }
            if(this.data.iqwig['iqwig_type_amm'])  
            { 
                this.list_check_box['detail_MaSpac'].enable = true
            }

            if(this.check_detail_conclusion(this.data, this.$i18n.locale) || 
                this.data.iqwig.iqwig_extent_probability.length > 0) 
                { 
                    this.list_check_box['detail_Conclusion'].enable = true
                }
            if(this.$i18n.locale == 'de' && this.data.iqwig['commentde'] || 
                this.$i18n.locale != 'de' && this.data.iqwig['commentfr'])
                { 
                    this.list_check_box['detail_Commentfr'].enable = true 
                }
            if(this.$i18n.locale == 'de' && this.data.iqwig['backgroundde'] || 
                this.$i18n.locale != 'de' && this.data.iqwig['background'] ||
                this.data.iqwig.iqwig_research_question.length > 0 ||
                this.$i18n.locale == 'de' && this.data.iqwig['benefit_assessmentde'] ||
                this.$i18n.locale != 'de' && this.data.iqwig['benefit_assessment'] ||
                this.data.iqwig.iqwig_benefit_assessment.length > 0 ||
                this.data.iqwig.iqwig_result_benefit_assessment.length > 0 ||
                this.$i18n.locale == 'de' && this.data.iqwig['quality_of_lifede'] || 
                this.$i18n.locale != 'de' && this.data.iqwig['quality_of_life'] || 
                this.$i18n.locale == 'de' && this.data.iqwig['adversede'] || 
                this.$i18n.locale != 'de' && this.data.iqwig['adverse'] ||
                this.$i18n.locale == 'de' && this.data.iqwig['relevant_subde'] || 
                this.$i18n.locale != 'de' && this.data.iqwig['relevant_sub'] ||
                this.$i18n.locale == 'de' && this.data.iqwig['otherde'] || 
                this.$i18n.locale != 'de' && this.data.iqwig['other'] ||
                this.$i18n.locale == 'de' && this.data.iqwig['mortalityde'] || 
                this.$i18n.locale != 'de' && this.data.iqwig['mortality'] ||
                this.$i18n.locale == 'de' && this.data.iqwig['morbidityde'] || 
                this.$i18n.locale != 'de' && this.data.iqwig['morbidity'])
                { 
                    this.list_check_box['detail_background'].enable = true 
                }
            if(this.data.iqwig.iqwig_extent_probability.length > 0)
            { 
                for(let key in this.data.iqwig.iqwig_extent_probability){
                    this.iqwig_extent_probability_show[key] = 0
                }

                this.list_check_box['detail_IqwigExtentProbability'].enable = true 
            }
            if((this.$i18n.locale == 'de' && this.data.iqwig['studie_assessmentde']) || 
                (this.$i18n.locale != 'de' && this.data.iqwig['studie_assessment']) ||
                (this.$i18n.locale == 'de' && this.data.iqwig['study_characterde']) || 
                (this.$i18n.locale != 'de' && this.data.iqwig['study_character']) ||
                // this.data.data_links['EvaluationEssaisCliniqueAspiration'] && this.data.data_links['EvaluationEssaisCliniqueAspiration'].length > 0 ||
                this.data.iqwig.iqwig_study_character.length > 0)
                { 
                    this.list_check_box['detail_studie_assessment'].enable = true 
                }
            if(this.$i18n.locale == 'de' && this.data.iqwig['evaluation_o_addde'] || 
                this.$i18n.locale != 'de' && this.data.iqwig['evaluation_o_add'] )
                { 
                    this.list_check_box['detail_evaluation_o_add'].enable = true 
                }
            if(this.data.iqwig.iqwig_press_release.length > 0)
            { 
                for(let key in this.data.iqwig.iqwig_press_release){
                    this.PressRelease_show[key] = 0
                }                    
                this.list_check_box['detail_IqwigPressRelease'].enable = true 
            }  
            
            if(this.data.data_links && this.data.data_links['EconomicEvaluation'] && this.data.data_links['EconomicEvaluation'].length > 0) { 

                this.list_check_box['detail_EconomicEvaluation'].enable = true 
            }                
            if(this.data.essais_clinique && this.data.essais_clinique.length > 0){
                this.list_check_box['detail_clinical_trials'].enable = true
            }                
            if(key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length > 0){ 
                this.list_check_box['detail_key_documents'].enable = true
            }

        }
    },
    methods : {
        DDMMMYY,
        check_empty,
        check_detail_conclusion,
        key_documents,
        gen_IqwigResultBenefitAssessmentEndpoint(param){
            let IqwigResultBenefitAssessmentEndpoint = {}
            for(let key in param) {
                if(!IqwigResultBenefitAssessmentEndpoint[param[key]['type']]){
                    IqwigResultBenefitAssessmentEndpoint[param[key]['type']] = []
                }
                IqwigResultBenefitAssessmentEndpoint[param[key]['type']].push(param[key])
            }
            return IqwigResultBenefitAssessmentEndpoint
        },
        PressRelease_toggle(key){
            if(this.PressRelease_show[key] === 1){
                this.PressRelease_show[key] = 0
            }else{
                this.PressRelease_show[key] = 1
            } 
            this.$forceUpdate();
        },
        iqwig_extent_probability_toggle(key){
            if(this.iqwig_extent_probability_show[key] === 1){
                this.iqwig_extent_probability_show[key] = 0
            }else{
                this.iqwig_extent_probability_show[key] = 1
            } 
            this.$forceUpdate();
        }
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
        FULL_BASE_URL(){
            return process.env.VUE_APP_URL_PRISMACCESS
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },       			
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        }        
    },
}

</script>

<style>
a { 
    cursor: pointer;
}
.accordion-body { 
    background-color : #FEF0E6;
}
.fa-angle-up {
    color : #ff6300;
}
.box-toggle a:hover i {
    color: #ff6300;
}
section {
    margin-top : 10px;
    margin-bottom : 10px;
}

h2 {
    font-size : 1rem;
}
h1.page-title {
    color: #ff6300 !important;
    letter-spacing: normal;
    margin: 0;
    font-weight: lighter;
}

</style>